import React, { FC, Fragment } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Image, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import SelectFillIcon from 'wix-ui-icons-common/on-stage/SelectFill';

import { DecoratedPost } from '@api/member.models';
import { classes, st } from './ItemContent.st.css';

interface ItemContentProps {
  isMobile?: boolean;
  data: DecoratedPost;
  handleOpenItem: () => void;
}

const ItemContent: FC<ItemContentProps> = ({
  isMobile = false,
  data,
  handleOpenItem,
}) => {
  const { t } = useTranslation();

  const postMedia = data.contentMedia ? (
    <Image
      alt={data.contentMedia.alt}
      src={data.contentMedia.src}
      fluid
      width={580}
      height={268}
      resize={ImageResizeOptions.cover}
    />
  ) : null;

  return (
    <Fragment>
      <div
        className={st(classes.itemContentContainer, {
          withMedia: !!postMedia,
        })}
      >
        <div className={st(classes.titleContainer)}>
          {data.hasBestAnswer && (
            <div>
              <SelectFillIcon className={st(classes.titleMark)} size={24} />
            </div>
          )}
          {data.url ? (
            <a
              data-hook="posts-list-item-link"
              href={data.url}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                handleOpenItem();
              }}
              className={st(classes.title, classes.link)}
            >
              {data.title}
            </a>
          ) : (
            <div className={st(classes.title)}>{data.title}</div>
          )}
        </div>

        <div className={st(classes.itemSubTitle)}>
          {t('post-list-item.post-in-category', {
            category: data.category.title,
          })}
        </div>

        <div className={st(classes.itemContent)}>{data.content}</div>
      </div>

      {postMedia && (
        <div className={st(classes.itemContentMedia, { mobile: isMobile })}>
          {postMedia}
        </div>
      )}
    </Fragment>
  );
};

export default ItemContent;
