export const formatCount = (count: number) => {
  const countAbs = Math.abs(count);

  if (countAbs < 1000) {
    return count;
  }

  const countSign = Math.sign(count);
  const formattedCount = Math.floor(countAbs / 1000) * countSign;

  return `${formattedCount}k`;
};
