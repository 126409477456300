import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { st, classes } from './ErrorMessage.st.css';

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <div className={st(classes.container)}>
      <div className={st(classes.title)}>{t('broken-state.title')}</div>
      <div className={st(classes.subTitle)}>
        {t('broken-state.suggestions-title')}
      </div>
      <ul className={st(classes.list)}>
        <li>{t('broken-state.suggestion-1')}</li>
        <li>{t('broken-state.suggestion-2')}</li>
        <li>{t('broken-state.suggestion-3')}</li>
      </ul>
    </div>
  );
};

export default ErrorMessage;
