import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { DecoratedPost } from '@api/member.models';
import { useBiLogger } from '@common/providers';
import { ItemContainer, ItemHeader, ItemContent, ItemFooter } from './elements';
import { getAvailableActions } from './PostListItem.utils';

interface PostListItemProps {
  isRTL?: boolean;
  isMobile?: boolean;
  data: DecoratedPost;
  handleOpenItem?: (postUrl: string) => void;
  handleDeleteItem?: (post: DecoratedPost) => void;
  handleCopyItemLink?: (postUrl: string) => void;
}

const PostListItem: FC<PostListItemProps> = ({
  isRTL,
  isMobile,
  data,
  handleOpenItem,
  handleDeleteItem,
  handleCopyItemLink,
}) => {
  const { t } = useTranslation();
  const { logEvent } = useBiLogger();

  const availableActions = getAvailableActions(
    data,
    {
      handleDeleteItem,
      handleCopyItemLink,
    },
    t,
  );

  const COMMON_BI_DATA = {
    type: 'post',
    post_id: data.id,
  };

  return (
    <ItemContainer isMobile={isMobile}>
      <ItemHeader
        isMobile={isMobile}
        owner={data.owner}
        createdDate={data.createdDate}
        availableActions={availableActions}
        logEvent={(biData: any) => {
          logEvent({
            ...COMMON_BI_DATA,
            ...biData,
          });
        }}
      />
      <ItemContent
        isMobile={isMobile}
        data={data}
        handleOpenItem={() => {
          if (handleOpenItem) {
            logEvent({
              ...COMMON_BI_DATA,
              evid: 516,
            });
            handleOpenItem(data.url);
          }
        }}
      />
      <ItemFooter
        isRTL={isRTL}
        stats={data.stats}
        interaction={data.postInteraction}
      />
    </ItemContainer>
  );
};

export default PostListItem;
