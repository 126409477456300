import React, { FC } from 'react';

import { classes, st } from './WidgetContainer.st.css';

interface WidgetContainerProps {
  isRTL?: boolean;
  isMobile?: boolean;
  title: string;
  dataHook?: string;
}

const WidgetContainer: FC<WidgetContainerProps> = ({
  isRTL = false,
  isMobile = false,
  children,
  title,
  dataHook,
}) => {
  return (
    <div
      data-hook={dataHook}
      className={st(classes.container, { mobile: isMobile, rtl: isRTL })}
    >
      {!isMobile && <h2 className={st(classes.title)}>{title}</h2>}
      <div>{children}</div>
    </div>
  );
};

export default WidgetContainer;
