import React from 'react';
import { ThreeDotsLoader as ThreeDotsLoaderComponent } from 'wix-ui-tpa/cssVars';

import { classes } from './ThreeDotsLoader.st.css';

export const THREE_DOTS_LOADER_DATA_HOOK = 'three-dots-loader';

const ThreeDotsLoader = ({ customStyle }: { customStyle?: string }) => {
  return (
    <div
      className={customStyle ? customStyle : classes.loaderContainer}
      data-hook={THREE_DOTS_LOADER_DATA_HOOK}
    >
      <ThreeDotsLoaderComponent />
    </div>
  );
};

export default ThreeDotsLoader;
