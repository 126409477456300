import React from 'react';

import ErrorMessage from './ErrorMessage';

interface ErrorBoundaryProps {
  error?: any;
  handleLogError: (error: Error, info?: React.ErrorInfo) => void;
}

interface ErrorBoundaryState {
  error?: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: ErrorBoundaryState = {};

  static getDerivedStateFromError(error: Error) {
    return { error: error.toString() };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    const { handleLogError } = this.props;

    handleLogError(error, info);
  }

  render() {
    const { error } = this.props;

    if (this.state.error || error) {
      return <ErrorMessage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
