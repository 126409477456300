import React, { FC, useEffect } from 'react';
import { ToastSkin } from 'wix-ui-tpa/cssVars';

import Toast from './Toast';

interface ToastConfig {
  message: string;
  skin?: ToastSkin;
  isMobile?: boolean;
}

interface ReturnProps {
  showToast: (toastConfig: ToastConfig) => void;
  hideToast: () => void;
  Toast: FC;
}

const TOAST_DISPLAY_TIME = 5000;

export const useToast = (): ReturnProps => {
  const [show, setShow] = React.useState(false);
  const [currentToast, setCurrentToast] = React.useState<ToastConfig | null>(
    null,
  );

  const handleClose = () => {
    setShow(false);
    setCurrentToast(null);
  };

  const toastContent = () => (
    <Toast
      isMobile={currentToast!.isMobile}
      message={currentToast!.message}
      handleClose={handleClose}
      skin={currentToast?.skin || ToastSkin.success}
    />
  );

  useEffect(() => {
    if (show && currentToast) {
      // @TODO maybe store timeout in ref and also clear on button press?
      const hideTimeout = setTimeout(() => {
        setShow(false);
        setCurrentToast(null);
      }, TOAST_DISPLAY_TIME);

      return () => {
        clearTimeout(hideTimeout);
      };
    }
  }, [show, currentToast]);

  return {
    showToast: (config) => {
      setCurrentToast(config);
      setShow(true);
    },
    hideToast: () => setShow(false),
    Toast: () => (show ? toastContent() : null),
  };
};
