import React, { FC } from 'react';

import Modal from './Modal';

interface ReturnProps<K> {
  showModal: () => void;
  hideModal: () => void;
  Modal: FC<K>;
}
interface ModalContentProps {
  handleCloseModal: () => void;
}

export const useModal = <T,>(
  ModalContent: FC<T & ModalContentProps>,
  options?: { onOpen?: () => void; onClose?: () => void },
): ReturnProps<Omit<T, 'handleCloseModal'>> => {
  const [show, setShow] = React.useState(false);

  const handleOpen = () => {
    setShow(true);
    options?.onOpen && options.onOpen();
  };

  const handleClose = () => {
    setShow(false);
    options?.onClose && options.onClose();
  };

  const modalContent: FC<T> = (props: T) => (
    <Modal handleClose={handleClose}>
      <ModalContent handleCloseModal={handleClose} {...props} />
    </Modal>
  );

  return {
    showModal: handleOpen,
    hideModal: handleClose,
    Modal: (props: any) => {
      return show ? modalContent(props) : null;
    },
  };
};
