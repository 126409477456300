import React, { FC } from 'react';

import { StatsBar } from '@common/components';
import { classes, st } from './ItemFooter.st.css';
import { PostInteraction } from '@wix/ambassador-forum-v1-category/build/cjs/types.impl';

interface ItemFooterProps {
  isRTL?: boolean;
  stats: {
    likes?: number;
    comments?: number;
    views?: number;
  };
  interaction: PostInteraction;
}

const ItemFooter: FC<ItemFooterProps> = ({ isRTL, stats, interaction }) => {
  const likesIconType =
    interaction === PostInteraction.REACTION ? 'emotions' : 'none';

  return (
    <div className={st(classes.statsBar)}>
      <StatsBar
        isRTL={isRTL}
        likes={stats.likes}
        comments={stats.comments}
        views={stats.views}
        likesIconType={likesIconType}
      />
    </div>
  );
};

export default ItemFooter;
