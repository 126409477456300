import React, { FC } from 'react';

import { st, classes } from './EmptyList.st.css';

interface EmptyListProps {
  title: string;
  subTitle: string;
}

const EmptyList: FC<EmptyListProps> = ({ title, subTitle }) => {
  return (
    <div className={st(classes.container)}>
      <div className={st(classes.title)}>{title}</div>
      <div className={st(classes.subTitle)}>{subTitle}</div>
    </div>
  );
};

export default EmptyList;
