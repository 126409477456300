import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { usePermissions } from '@common/providers';
import { DecoratedPost } from '@api/member.models';
import { ItemList, EmptyList } from '@common/components';
import { PostListItem } from '../../components';

interface PostListProps {
  isMobile?: boolean;
  isRTL?: boolean;
  isLoading?: boolean;
  posts?: DecoratedPost[];
  handleOpenItem: (postUrl: string) => void;
  handleDeleteItem: (postData: DecoratedPost) => void;
  handleCopyItemLink: (postUrl: string) => void;
}

const PostList: FC<PostListProps> = ({
  isRTL = false,
  isMobile = false,
  isLoading,
  posts = [],
  handleOpenItem,
  handleDeleteItem,
  handleCopyItemLink,
}) => {
  const { t } = useTranslation();
  const { can } = usePermissions();

  if (!isLoading && posts.length === 0) {
    return (
      <EmptyList title={t('no-posts.title')} subTitle={t('no-posts.content')} />
    );
  }

  return (
    <div>
      <ItemList
        isMobile={isMobile}
        isLoading={isLoading}
        data={posts}
        renderItem={(post) => {
          const canDeletePost = can('delete', 'post', post);

          return (
            <PostListItem
              isRTL={isRTL}
              isMobile={isMobile}
              data={post}
              handleOpenItem={handleOpenItem}
              handleDeleteItem={canDeletePost ? handleDeleteItem : undefined}
              handleCopyItemLink={handleCopyItemLink}
            />
          );
        }}
      />
    </div>
  );
};

export default PostList;
