import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

type IStylesParams = {
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
  backgroundColor: StyleParamType.Color;
  borderColor: StyleParamType.Color;
  borderWidth: StyleParamType.Number;
  itemBackgroundColor: StyleParamType.Color;
  itemBorderColor: StyleParamType.Color;
  itemBorderWidth: StyleParamType.Number;
  itemHoverColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  textFont: {
    key: 'page-titleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  textColor: {
    key: 'page-titleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  backgroundColor: {
    key: 'page-backgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  borderColor: {
    key: 'page-borderColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  borderWidth: {
    key: 'page-borderWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBackgroundColor: {
    key: 'page-item-backgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  itemBorderColor: {
    key: 'page-item-borderColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  itemBorderWidth: {
    key: 'page-item-borderWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  itemHoverColor: {
    key: 'page-item-hover-color',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
});
