/* !!!!! This file is copy https://github.com/wix-private/wix-comments/blob/master/comments-web/comments-ooi-client/src/common/services/copy-text-to-clipboard.ts */

export const copyTextToClipboard = async (
  input: string,
  { target = document.body } = {},
): Promise<boolean> => {
  const element = document.createElement('textarea');
  const previouslyFocusedElement = document.activeElement;

  element.value = input;

  // Prevent keyboard from showing on mobile
  element.setAttribute('readonly', '');

  element.style.contain = 'strict';
  element.style.position = 'absolute';
  element.style.left = '-9999px';
  element.style.fontSize = '12pt'; // Prevent zooming on iOS

  const selection = document.getSelection();
  let originalRange = false;
  // @ts-expect-error
  if (selection.rangeCount > 0) {
    // @ts-expect-error
    originalRange = selection.getRangeAt(0);
  }

  target.append(element);

  // For some reason timeout needed after append in MA mobile
  await new Promise<void>((res) => res());

  element.select();

  // Explicit selection workaround for iOS
  element.selectionStart = 0;
  element.selectionEnd = input.length;

  let isSuccess = false;

  try {
    isSuccess = document.execCommand('copy');
  } catch {}

  element.remove();

  if (originalRange) {
    // @ts-expect-error
    selection.removeAllRanges();
    // @ts-expect-error
    selection.addRange(originalRange);
  }

  // Get the focus back on the previously focused element, if any
  if (previouslyFocusedElement) {
    // @ts-expect-error
    previouslyFocusedElement.focus();
  }

  return isSuccess;
};
