import React, { FC } from 'react';
import { isNumber } from 'lodash';
import LikeHeartIcon from 'wix-ui-icons-common/on-stage/LikeHeart';
import CommentIcon from 'wix-ui-icons-common/on-stage/Comment';
import ReplyIcon from 'wix-ui-icons-common/on-stage/Reply';
import VisibleIcon from 'wix-ui-icons-common/on-stage/Visible';
import ReactionIcon from 'wix-ui-icons-common/on-stage/Reaction';

import { formatCount } from './StatsBar.utils';
import { classes, st } from './StatsBar.st.css';

interface StatComponentProps {
  isRTL: boolean;
  value: number | string;
  iconComponent: FC<any>;
}

const StatComponent: FC<StatComponentProps> = ({
  isRTL,
  value,
  iconComponent: Icon,
}) => {
  const styleStateProps = { rtl: isRTL };

  return (
    <div className={st(classes.statContainer, styleStateProps)}>
      <div className={st(classes.iconContainer, styleStateProps)}>
        <Icon size={20} />
      </div>
      <div>{value}</div>
    </div>
  );
};
interface StatsBarProps {
  isRTL?: boolean;
  score?: number;
  likes?: number;
  comments?: number;
  replies?: number;
  views?: number;
  likesIconType?: 'likes' | 'emotions' | 'none';
}

const StatsBar: FC<StatsBarProps> = ({
  isRTL = false,
  score,
  likes,
  comments,
  views,
  replies,
  likesIconType = 'likes',
}) => {
  return (
    <div className={st(classes.container)}>
      {isNumber(score) && (
        <div className={st(classes.statContainer, { rtl: isRTL })}>
          <div className={st(classes.iconContainer, { rtl: isRTL })}>
            {/* @TODO temporary inline svg, while icon is not available in wix-ui-tp */}
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 10V15.5H4.5V10V9.5H4H0.960328L7 0.871897L13.0397 9.5H10H9.5V10Z"
                stroke="currentColor"
              />
            </svg>
          </div>
          <div>{formatCount(score)}</div>
          <div className={st(classes.iconContainer, { rtl: isRTL })}>
            {/* @TODO temporary inline svg, while icon is not available in wix-ui-tp */}
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 6V6.5H10H13.0397L7 15.1281L0.960327 6.5H4H4.5V6V0.5L9.5 0.5L9.5 6Z"
                stroke="currentColor"
              />
            </svg>
          </div>
        </div>
      )}
      {isNumber(likes) && likesIconType !== 'none' && (
        <StatComponent
          isRTL={isRTL}
          value={formatCount(likes)}
          iconComponent={
            likesIconType === 'emotions' ? ReactionIcon : LikeHeartIcon
          }
        />
      )}
      {isNumber(comments) && (
        <StatComponent
          isRTL={isRTL}
          value={formatCount(comments)}
          iconComponent={CommentIcon}
        />
      )}
      {isNumber(replies) && (
        <StatComponent
          isRTL={isRTL}
          value={formatCount(replies)}
          iconComponent={ReplyIcon}
        />
      )}
      {isNumber(views) && (
        <StatComponent
          isRTL={isRTL}
          value={formatCount(views)}
          iconComponent={VisibleIcon}
        />
      )}
    </div>
  );
};

export default StatsBar;
