import React, { FC } from 'react';

import { classes, st } from './ItemContainer.st.css';

interface ItemContainerProps {
  isMobile?: boolean;
}

const ItemContainer: FC<ItemContainerProps> = ({
  isMobile = false,
  children,
}) => {
  return (
    <div className={st(classes.container, { mobile: isMobile })}>
      {children}
    </div>
  );
};

export default ItemContainer;
